/* eslint react/prop-types: 0 */
import { useCycleAssignedPhases, useCyclesGroupAssignedPhases } from '../CyclesHooks';
import React, { useMemo } from 'react';
import { Center, Loader, Paper, Select, Space } from '@mantine/core';
import NoticeAlert from '../../../../core/Alert/NoticeAlert';

export function PhaseSelection ({ cycles, form }) {
  return (
    <>
      {cycles.length > 1
        ? (
        <MultiCyclePhaseSelection cycles={cycles} form={form} />
          )
        : (
        <SinglePhaseSelection cycle={cycles[0] ?? null} form={form} />
          )}
    </>
  )
}

export function SinglePhaseSelection ({ cycle, form }) {
  const [phases, , loading] = useCycleAssignedPhases(cycle, !cycle)

  return (
    <PhaseSelectionEditor phases={phases} loading={loading} form={form} />
  )
}

function MultiCyclePhaseSelection ({ cycles, form }) {
  const [phases, , loading] = useCyclesGroupAssignedPhases(cycles)

  return (
    <PhaseSelectionEditor phases={phases} loading={loading} form={form} isMultiple={true} />
  )
}

function PhaseSelectionEditor ({ phases, loading, form, isMultiple = false }) {
  const phaseOptions = useMemo(() => {
    return phases?.data?.map(phase => ({ label: phase.name, value: phase.id.toString() })) ?? []
  }, [phases])

  return (
    <Paper shadow="md" radius="md" p="md" withBorder>
      {loading
        ? (
          <Center>
            <Loader />
          </Center>
          )
        : (
          <Select
            label='Phase'
            description='Phase associated with invite'
            searchable
            data={ phaseOptions }
            { ...form.getInputProps('phase') }
          />
          )}
      {!loading && !phaseOptions.length && (
        <>
          <Space h='xxl' />
          <NoticeAlert title={isMultiple ? 'No Assigned Phases in Common' : 'No Assigned Phases'}>
            Must have at least one {isMultiple ? 'shared ' : ''}phase used in an active stage for {isMultiple ? 'all associated cycles' : 'this cycle'}!
          </NoticeAlert>
        </>
      )}
    </Paper>
  )
}
