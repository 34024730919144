/* eslint react/prop-types: 0 */
import { BaseModal } from '../../../../build/assessment/EditorComponents/BaseModal';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { CyclesContext } from '../../../contact/ContactContexts';
import { ConfigEdit } from './ConfigEdit';
import { useLazyValidatePhaseInvites } from '../CyclesHooks';

export const ConfigModal = memo(function ConfigModal (
  {
    updateInviteConfig,
    inviteConfigTemplate,
    offerOption,
    applicants,
    setPhaseInviteStatuses
  }
) {
  const [showing, setShowing] = useState(offerOption)
  const [validatedApplicants, setValidatedApplicants] = useState(null)
  const [validatedConfig, setValidatedConfig] = useState(null)
  const cycles = useContext(CyclesContext)
  const [trigger, validationData, validationQuerying] = useLazyValidatePhaseInvites()

  const validateValuesWithApplicants = useCallback((values, applicantIds = null, updateConfig = true) => {
    return trigger({ phaseId: values?.phase ?? '0', applicantIds: applicantIds ?? applicants, cycleIds: cycles })
      .unwrap()
      .then(response => {
        console.debug('Got validate invite config template response', { response })
        notifications.show(
          {
            color: 'green',
            title: 'Invite Validated',
            message: 'Your phase invite configuration looks good!',
            autoClose: 5000
          }
        )
        if (!applicantIds) {
          setShowing(false)
          setValidatedApplicants(applicants)
          if (updateConfig) {
            updateInviteConfig(values)
            setValidatedConfig(values)
          }
        } else {
          setValidatedApplicants(applicants)
        }
        console.debug('Exiting validate callback', { values, applicantIds, applicants })
      })
      .catch(err => {
        console.error('Got validate invite config template error', { err })
        notifications.show(
          {
            color: 'red',
            title: 'Phase Invite Validation Error',
            message: 'Your phase invite configuration cannot be used for all ' + (applicants?.length ? 'selected applicants.' : 'selected cycles.'),
            autoClose: 7000
          }
        )
      })
  }, [applicants, cycles, updateInviteConfig, trigger])

  useEffect(() => {
    if (!inviteConfigTemplate && validatedConfig) {
      console.debug(
        'Clearing form-change-proof validated config template from form change.',
        { inviteConfigTemplate, validatedConfig }
      )
      setValidatedConfig(null)
    }
  }, [validatedConfig, inviteConfigTemplate])

  const onSubmit = async (values) => {
    console.info('Submitting invite config template form data for validation.', values)

    return validateValuesWithApplicants(values)
  }

  useEffect(() => {
    if (validatedConfig && applicants?.length) {
      console.debug('Applicants, validatedConfig, or validatedApplicants updated', { applicants, validatedApplicants, validatedConfig })
      if (applicants !== validatedApplicants) {
        const nonValidated = applicants.filter(applicantId => !validatedApplicants?.includes(applicantId))
        if (nonValidated.length) {
          console.debug('Validating new applicants', { nonValidated, validatedApplicants, applicants })
          validateValuesWithApplicants(validatedConfig, nonValidated, false)
        }
      }
    }
  }, [applicants, validatedConfig, validatedApplicants, validateValuesWithApplicants])

  const onCancel = useCallback(() => {
    setShowing(false)
  }, [])

  useEffect(() => {
    if (validationData) {
      console.debug('Updating from validation data change', { validationData })
      setPhaseInviteStatuses((prev) => {
        const samePhase = prev?.validatedPhase === validationData.validatedPhase
        if (!samePhase) {
          return {
            neverInvited: [...validationData.neverInvited],
            continueFromLastPhase: [...validationData.continueFromLastPhase],
            resumePhase: [...validationData.resumePhase],
            retakes: [...validationData.retakes],
            validatedPhase: validationData.validatedPhase
          }
        }
        const allUpdatedIds = new Set([
          ...validationData.neverInvited.map(elem => elem.id),
          ...validationData.continueFromLastPhase.map(elem => elem.id),
          ...validationData.resumePhase.map(elem => elem.id),
          ...validationData.retakes.map(elem => elem.id)
        ])
        console.debug('Merging validation with previous data', { prev, allUpdatedIds })
        return {
          neverInvited: [...(prev?.neverInvited?.filter(elem => !allUpdatedIds.has(elem.id)) ?? []), ...validationData.neverInvited],
          continueFromLastPhase: [...(prev?.continueFromLastPhase?.filter(elem => !allUpdatedIds.has(elem.id)) ?? []), ...validationData.continueFromLastPhase],
          resumePhase: [...(prev?.resumePhase?.filter(elem => !allUpdatedIds.has(elem.id)) ?? []), ...validationData.resumePhase],
          retakes: [...(prev?.retakes?.filter(elem => !allUpdatedIds.has(elem.id)) ?? []), ...validationData.retakes],
          validatedPhase: validationData.validatedPhase
        }
      })
    }
  }, [setPhaseInviteStatuses, validationData])

  useEffect(() => {
    if (offerOption) {
      console.debug('Invite config modal auto-opening', { offerOption })
      setShowing(true)
    }
  }, [offerOption])

  console.debug('ConfigModal updating', { offerOption, cycles, inviteConfigTemplate, validatedConfig, validatedApplicants, validationData, showing })

  return (
    <>
      {!!offerOption && !!inviteConfigTemplate && (
        <Button variant='subtle' size='sm' color='gray.6' onClick={() => updateInviteConfig(null)}>Remove Phase Invite</Button>)}
      {!!offerOption && <Button variant='subtle' size='sm' onClick={() => setShowing(true)}>{inviteConfigTemplate ? 'Edit' : 'Use'} Phase Invite</Button>}
      <BaseModal
        title=''
        showing={showing}
        onClose={() => setShowing(false)}
        withCloseButton={false}
        size='auto'
      >
        <ConfigEdit
          cycles={cycles}
          template={inviteConfigTemplate}
          onSubmit={onSubmit}
          onCancel={onCancel}
          validating={validationQuerying}
        />
      </BaseModal>
    </>
  )
})
