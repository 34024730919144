/* eslint-disable react/prop-types */
import { Select } from '@mantine/core'
import React, { useMemo } from 'react'
import { formatToOptions } from '../../../js/util/DataUtil'

export default function TemplateSelection ({ form, index, templates, getTemplate, onChange: updateContent }) {
  const templateOptions = useMemo(() => {
    console.debug('TemplateSelection options updating', { templates })
    return formatToOptions([...templates], { label: 'title' })
  }, [templates])
  console.debug('TemplateSelection component updating', { templateOptions })
  return (
    <Select
      label='Template'
      description='Choose a template to start with'
      data={templateOptions}
      placeholder='Blank'
      clearable
      searchable
      {...form.getInputProps(`messages.${index}.template`)}
      onChange={value => {
        form.setFieldValue(`messages.${index}.template`, value)

        if (value !== null) {
          getTemplate(value).then(t => {
            form.setFieldValue(`messages.${index}.subject`, t.subject)

            if (typeof updateContent !== 'undefined') {
              updateContent(t.template)
            }
          })
        } else {
          form.setFieldValue(`messages.${index}.subject`, '')
        }
      }}
    />
  )
}
