import React, { useMemo } from 'react'
import { CyclesContext } from './ContactContexts'
import PropTypes from 'prop-types'

export function CyclesContextProvider ({ cycles = null, cycle = null, children }) {
  const allCycles = useMemo(() => {
    console.debug('CyclesContextProvider updating context', { cycles, cycle })
    return [...(cycles ?? []), ...(cycle ? [cycle] : [])]
  }, [cycles, cycle])
  return (
    <CyclesContext.Provider value={allCycles}>
      {children}
    </CyclesContext.Provider>
  )
}

CyclesContextProvider.propTypes = {
  cycles: PropTypes.arrayOf(PropTypes.number),
  cycle: PropTypes.number,
  children: PropTypes.node.isRequired
}
