/* eslint react/prop-types: 0 */
import {
  Button,
  Checkbox,
  Group,
  Select,
  Space,
  Stack,
  TextInput,
  Title,
  Center,
  Loader,
  Paper,
  Box,
  Modal
} from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useDebouncedField } from '../../../../forms/UpdateOnSubmitField';
import { DateTimePicker } from '@mantine/dates';
import { CyclePassRoute } from '../../../../../js/generated/enums/CyclePassRoute';
import {
  inviteStyleOptions,
  thankYouStyleOptions
} from '../landing/LandingPageStyle';
import { useAssessmentInviteDetail } from './AssessmentInvitesHooks';
import { useAccount } from '../../../../util/Hooks';
import { useAllActiveCycleApplicants } from '../CyclesHooks';
import {
  useAssessmentInviteLockMutation,
  useCreateAssessmentInviteMutation,
  useEditAssessmentInviteMutation
} from './AssessmentInvitesApi';
import { useDisclosure } from '@mantine/hooks';
import { LinkPrefixEditor, OverrideDefaultFieldInput } from '../open/OpenInvitesCreate';
import { InviteStyle } from '../../../../../js/generated/enums/InviteStyle';
import { ThankYouStyle } from '../../../../../js/generated/enums/ThankYouStyle';
import {
  combineApplicantName,
  formatDateFromSource,
  formatDateToSource,
  formatNullableBooleanChoiceToSource,
  formatPhaseInviteLink
} from '../formatUtil';
import { PreviewButton } from '../nav/CycleNavButton';
import { ComingSoonTooltip } from '../nav/ComingSoonTooltip';
import { useConfirmNavigate } from '../CycleInviteHooks';
import { SinglePhaseSelection } from './PhaseSelection';

export function AssessmentInviteEdit () {
  const { cycleId, passId, inviteId } = useParams()
  const [invite, , loading] = useAssessmentInviteDetail(inviteId, cycleId, !inviteId)
  const account = useAccount()

  return (
    <>
      {!!loading && (
        <>
          <Space h='xxl' />
          <Center>
            <Loader type='bars' />
          </Center>
        </>
      )}
      {!loading && <AssessmentInviteEditControlWrapper cycleId={cycleId} passId={passId} invite={invite} account={account} />}
    </>
  )
}

function AssessmentInviteEditControlWrapper ({ cycleId, passId, invite, account, showRecipient = true }) {
  const navigate = useNavigate()
  const [sendEditData, { isLoading: editProcessing }] = useEditAssessmentInviteMutation()
  const [sendCreateData, { isLoading: createProcessing }] = useCreateAssessmentInviteMutation()
  const sendData = invite ? sendEditData : sendCreateData
  const processing = invite ? editProcessing : createProcessing
  const [toggleLock, { isLoading: toggleLockProcessing }] = useAssessmentInviteLockMutation()
  const [toggleInviteId, setToggleInviteId] = useState(invite?.id ?? null)
  const [togglePassId, setTogglePassId] = useState(passId ?? null)
  const [opened, { open, close }] = useDisclosure(false)
  const setConfirmNavigate = useConfirmNavigate()
  const originalLockedSetting = invite?.locked ?? null

  const onSubmit = useCallback((values) => {
    console.info('Submitting invite form data.', values)
    const { locked: newLockedSetting, ...otherValues } = (values ?? {})
    sendData({ ...(otherValues ?? {}), ...(toggleInviteId ? { id: toggleInviteId } : {}), cycleId })
      .unwrap()
      .then(response => {
        console.debug('Got post phase invite form response', response, originalLockedSetting, newLockedSetting)
        notifications.show(
          {
            color: 'green',
            title: 'Submission Success',
            message: 'Your phase invite was saved!',
            autoClose: 8000
          }
        )
        if (
          ((originalLockedSetting !== null) && (originalLockedSetting !== newLockedSetting)) ||
          ((originalLockedSetting === null) && newLockedSetting)
        ) {
          if ((originalLockedSetting === null) && response?.id) {
            setToggleInviteId(response.id)
            setTogglePassId(passId ?? response?.cyclePassId)
          }
          open()
        } else {
          setConfirmNavigate(false)
          const navigateId = toggleInviteId ?? response?.id
          const cyclePassId = passId ?? response?.cyclePassId
          const path = (navigateId && cyclePassId)
            ? generatePath(CyclePassRoute.CyclePassInviteDetail, { cycleId: cycleId.toString(), inviteId: navigateId.toString(), passId: cyclePassId.toString() })
            : generatePath(CyclePassRoute.CycleInvitesList, { cycleId: cycleId.toString() })
          console.debug('Generated path for phase invite detail / fallback list if no id', { path, navigateId, cyclePassId, cycleId })
          navigate(path)
        }
      })
      .catch(e => {
        console.error('Post phase invite form error response', e)
        notifications.show( // TODO [cycle pass full features] validation errors on fields
          {
            color: 'red',
            title: 'Submission Error',
            message: 'There was an error saving your phase invite!',
            autoClose: 8000
          }
        )
      })
  }, [cycleId, sendData, originalLockedSetting, toggleInviteId, passId, setConfirmNavigate, open, navigate])

  const onCancel = () => {
    setConfirmNavigate(false)
    navigate(-1)
  }

  const closeToggleLockModal = () => {
    setConfirmNavigate(false)
    close()
    const path = (toggleInviteId && togglePassId)
      ? generatePath(CyclePassRoute.CyclePassInviteDetail, { cycleId: cycleId.toString(), inviteId: toggleInviteId.toString(), passId: togglePassId.toString() })
      : generatePath(CyclePassRoute.CycleInvitesList, { cycleId: cycleId.toString() })
    console.debug('Generated path for assessment invite detail / fallback list if no id', { path, toggleInviteId, togglePassId, cycleId })
    navigate(path)
  }

  const changeLockSetting = () => {
    const newLockedSetting = !originalLockedSetting
    toggleLock({ id: toggleInviteId, cycleId: cycleId, unlock: !!originalLockedSetting })
      .unwrap()
      .then(response => {
        console.debug('Got post assessment invite lock toggle response - TODO', response)
        notifications.show(
          {
            color: 'green',
            title: 'Lock Toggle Success',
            message: `Your assessment invite was ${newLockedSetting ? 'locked' : 'unlocked'}!`,
            autoClose: 8000
          }
        )
        closeToggleLockModal()
      })
      .catch(e => {
        console.error('Post assessment invite lock toggle error response', e)
        notifications.show(
          {
            color: 'red',
            title: 'Lock Toggle Error',
            message: 'There was an error changing the lock setting!',
            autoClose: 8000
          }
        )
      })
  }

  const previewRedirectLink = generatePath(CyclePassRoute.CycleInvitesCreatePreview, { cycleId })

  return (
    <>
      <InviteEdit
        cycleId={cycleId}
        passId={passId}
        invite={invite}
        account={account}
        processing={processing || toggleLockProcessing || opened}
        onSubmit={onSubmit}
        onCancel={onCancel}
        setConfirmNavigate={setConfirmNavigate}
        showRecipient={showRecipient}
        previewRedirectLink={previewRedirectLink}
      />
      <Modal
        opened={opened}
        onClose={closeToggleLockModal}
        title={`Danger: ${originalLockedSetting ? 'Unlock' : 'Lock'} Invitation`}
      >
        <Title>
          Are you sure you want to {originalLockedSetting ? 'unlock' : 'lock'} this invitation? This will immediately affect anyone using the link.
        </Title>
        <Group>
          <Button variant='light' color='blue' onClick={closeToggleLockModal}>
            Cancel
          </Button>
          <Button variant='filled' color='yellow' onClick={changeLockSetting} loading={toggleLockProcessing}>
            {originalLockedSetting ? 'Unlock' : 'Lock'} Invitation
          </Button>
        </Group>
      </Modal>
    </>
  )
}

export function InviteEdit ({ cycleId, passId, invite, account, processing, onSubmit, onCancel, setConfirmNavigate = null, showRecipient = true, previewRedirectLink = null }) {
  console.debug('Invite edit updating', { invite, passId, cycleId, account, processing, showRecipient, previewRedirectLink })
  const canEditMetadata = !(invite?.open_invite_recipient)

  const form = useForm({
    name: 'applicant-assessment-invite-form',
    initialValues: {
      internalNote: (invite?.invite_config?.internal_note ?? invite?.internalNote ?? ''),
      landingPageHeader: (invite?.invite_config?.header ?? invite?.landingPageHeader ?? ''),
      landingPageMessage: (invite?.invite_config?.message ?? invite?.landingPageMessage ?? ''),
      locked: (invite?.locked ?? false),
      linkPrefix: (invite?.invite_config?.link_prefix ?? invite?.linkPrefix ?? ''),
      autoCloseByDate: formatDateFromSource(invite?.invite_config?.expiration_date ?? invite?.autoCloseByDate),
      landingPageStyle: (invite?.invite_config?.style ?? invite?.landingPageStyle ?? InviteStyle.Default).toString(),
      thankYouPageStyle: (invite?.invite_config?.thank_you_style ?? invite?.thankYouPageStyle ?? ThankYouStyle.ThankYouForYourTime).toString(),
      collectDemographics: (invite?.invite_config?.collect_demographics ?? invite?.collectDemographics ?? null),
      emailOnSubmit: (invite?.invite_config?.email_on_submit ?? invite?.emailOnSubmit ?? false),
      proctored: (invite?.invite_config?.proctored ?? invite?.proctored ?? null),
      customApplicantFlagName: (invite?.invite_config?.applicant_flag_template?.default_title ?? invite?.customApplicantFlagName ?? ''),
      customApplicantFlagDescription: (invite?.invite_config?.applicant_flag_template?.default_text ?? invite?.customApplicantFlagDescription ?? ''),
      customApplicantFlagIcon: (invite?.invite_config?.applicant_flag_template?.display_class ?? invite?.customApplicantFlagIcon)?.toString() ?? null,
      customApplicantFlagPMOnly: (invite?.invite_config?.applicant_flag_template ? (invite.invite_config.applicant_flag_template.type === 0) : invite?.customApplicantFlagPMOnly) ?? true,
      autoGeneratedComment: (invite?.invite_config?.auto_comment ?? invite?.autoGeneratedComment ?? ''),
      applicant: (invite?.applicant?.id?.toString() ?? invite?.applicant ?? null),
      email: (invite?.email?.id ?? invite?.email ?? ''),
      cyclePass: (invite?.cycle_pass?.id?.toString() ?? invite?.cyclePass ?? null),
      account: (invite?.account?.id?.toString() ?? invite?.account ?? null),
      assessors: (invite?.assessors?.map(assessor => assessor.id?.toString() ?? assessor) ?? []),
      facilitator: (invite?.facilitator?.id?.toString() ?? invite?.facilitator ?? null),
      password: (invite?.password ?? ''),
      phase: (invite?.phase?.id?.toString() ?? null)
    },

    transformValues: (values) => ({
      locked: values.locked,
      applicant: values.applicant,
      email: values.email ? { id: values.email } : null,
      cyclePass: values.cyclePass ? values.cyclePass : null,
      account: values.account,
      facilitator: values.facilitator,
      password: values.password,
      phase: values.phase,
      ...(canEditMetadata
        ? {
            inviteConfig: {
              header: values.landingPageHeader,
              message: values.landingPageMessage,
              internalNote: values.internalNote,
              expirationDate: formatDateToSource(values.autoCloseByDate),
              style: values.landingPageStyle,
              thankYouStyle: values.thankYouPageStyle,
              linkPrefix: values.linkPrefix,
              emailOnSubmit: values.emailOnSubmit,
              autoComment: values.autoGeneratedComment,
              collectDemographics: formatNullableBooleanChoiceToSource(values.collectDemographics),
              proctored: formatNullableBooleanChoiceToSource(values.proctored)
            }
          }
        : {})
    }),

    validate: {
      autoCloseByDate: (value, values) => (
        !value || (value >= (new Date())) || values.locked
      )
        ? null
        : 'Auto close by date must be on or after today.',
      customApplicantFlagDescription: (value, values) => (
        !values.customApplicantFlagName || !!value
      )
        ? null
        : 'Flag description must be set if creating a custom applicant flag.',
      customApplicantFlagIcon: (value, values) => (
        !values.customApplicantFlagName || !!value
      )
        ? null
        : 'Flag icon must be set if creating a custom applicant flag.',
      phase: (value) => (
        value
      )
        ? null
        : 'Must select phase for invite.'
    }
  })

  const formDirty = form.isDirty();

  useEffect(() => {
    if (formDirty) {
      setConfirmNavigate?.(true)
    }
  }, [formDirty, setConfirmNavigate])

  const inviteLink = formatPhaseInviteLink(invite?.url_suffix, form.values.linkPrefix)
  const previewParams = { title: form.values.landingPageHeader, message: form.values.landingPageMessage, style: form.values.landingPageStyle, locked: form.values.locked }

  return (
    <Box miw='100%'>
      <Center>
        <Box miw='35rem' maw='35rem'>
          <Stack>
            <Title ta='center'>
              {invite ? 'Edit' : 'Create'} Phase Invite
            </Title>
            <Center>
              <PreviewButton cycleId={cycleId} { ...previewParams } />
            </Center>
            {!!inviteLink && (
              <TextInput
                label='Link'
                value={inviteLink}
                disabled={true}
              />
            )}
            {!!showRecipient && <InviteRelatedFrame cycleId={cycleId} passId={passId} form={form} isAdmin={!!account?.access?.ADMIN} />}
            {!invite && <SinglePhaseSelection cycle={cycleId} form={form} />}
            {canEditMetadata
              ? (
              <InviteMetadataEditor
                form={form}
                hasSourceData={!!invite}
                isAdmin={!!account?.access?.ADMIN}
              />
                )
              : (
              <EditRelatedOpenInviteButton
                cycleId={cycleId}
                invite={invite}
                setConfirmNavigate={setConfirmNavigate}
              />
                )}
            <Group position='left' mt='md'>
              <Button
                color='green.6'
                loading={processing}
                onClick={() => {
                  console.debug('Calling on submit')
                  if (!form.validate().hasErrors) {
                    onSubmit(form.getTransformedValues())
                    console.debug('No form errors - called submit.')
                  } else {
                    console.warn('Not submitting due to validation errors.', form.validate())
                  }
                }}
              >
                Save
              </Button>
              <Button
                color='gray.6'
                onClick={() => {
                  setConfirmNavigate?.(false)
                  onCancel()
                }}
              >
                Discard
              </Button>
            </Group>
          </Stack>
        </Box>
      </Center>
    </Box>
  )
}

function EditRelatedOpenInviteButton ({ cycleId, invite, setConfirmNavigate }) {
  const navigate = useNavigate()

  const onClick = () => {
    setConfirmNavigate?.(false)
    const openInviteId = invite?.open_invite_recipient?.open_invite?.id?.toString()
    const path = (!cycleId || !openInviteId) ? null : generatePath(CyclePassRoute.CycleOpenInviteEdit, { cycleId, openInviteId })
    console.debug('Generated path for related open invite edit', { path, openInviteId })
    if (path) {
      navigate(path)
    } else {
      notifications.show(
        {
          color: 'red',
          title: 'Error Finding Related Open Invite',
          message: 'There was an error navigating to the related open invite page! Refreshing may help.',
          autoClose: 8000
        }
      )
    }
  }

  return (
    <>
      <Space h='xl' />
      <Button
        color='blue.6'
        onClick={onClick}
      >
        Edit Public Link
      </Button>
      <Space h='xl' />
    </>
  )
}

function InviteRelatedFrame ({ cycleId, passId, form, isAdmin = false }) {
  const params = useMemo(() => {
    return passId ? { passIds: [passId] } : {}
  }, [passId])

  const [applicants, , loading] = useAllActiveCycleApplicants(cycleId, params)
  return (
    <Paper shadow="md" radius="md" p="md" withBorder>
      {loading
        ? (
        <Center>
          <Loader />
        </Center>
          )
        : (
        <InviteRelatedEditor passId={passId} applicants={applicants} form={form} isAdmin={isAdmin} />
          )}
    </Paper>
  )
}

function makeNameFromApplicant (applicant) {
  return combineApplicantName(applicant) || 'Name Missing'
}

function InviteRelatedEditor ({ passId, applicants, form, isAdmin = false }) {
  console.debug('Invite related editor updating.', applicants, passId)
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  const applicantOptions = useMemo(() => {
    return applicants?.data?.map(applicant => ({ label: makeNameFromApplicant(applicant), value: applicant.id.toString(), meta: applicant })) ?? []
  }, [applicants])

  const selectedId = form.values.applicant
  const selectedApplicant = useMemo(() => {
    return applicantOptions.filter(elem => elem.value === selectedId)[0] ?? null
  }, [selectedId, applicantOptions])

  const updateFormEmail = useCallback((value) => {
    formRef.current.setValues({ email: value })
  }, [])

  const updateFormPassword = useCallback((value) => {
    formRef.current.setValues({ password: value })
  }, [])

  const emailProps = useDebouncedField(form.values.email, updateFormEmail)
  const passwordProps = useDebouncedField(form.values.password, updateFormPassword)

  useEffect(() => {
    if (selectedApplicant) {
      if (selectedApplicant.meta?.email_address) {
        formRef.current.setValues({ email: selectedApplicant.meta.email_address })
      }
    }
  }, [selectedApplicant])
  console.debug('Assessment invite form updating.', { selectedApplicant: selectedApplicant, selectedId: selectedId, email: form.values.email })

  return (
    <Stack gap='md'>
      <TextInput
        label='Email'
        description='Email address associated with invite'
        { ...form.getInputProps('email') }
        { ...emailProps }
        value={selectedApplicant?.meta?.email_address ?? emailProps.value}
        disabled={!!selectedApplicant?.meta?.email_address || !!passId}
      />
      <Select
        label='Applicant'
        description='Applicant associated with invite'
        searchable
        data={ applicantOptions }
        { ...form.getInputProps('applicant') }
        disabled={!!passId}
      />
      {!!isAdmin && (
        <ComingSoonTooltip>
          <Select
            label='Facilitator'
            searchable
            data={[]}
            { ...form.getInputProps('facilitator') }
            disabled={true}
          />
        </ComingSoonTooltip>
      )}
      <TextInput
        label='Password'
        description='Password required to access invite'
        placeholder='Optional'
        { ...form.getInputProps('password') }
        { ...passwordProps }
      />
    </Stack>
  )
}

function InviteMetadataEditor ({ form, hasSourceData = false, isAdmin = false }) {
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  const updateFormDescription = useCallback((value) => {
    formRef.current.setValues({ internalNote: value })
  }, [])

  const updateFormHeader = useCallback((value) => {
    formRef.current.setValues({ landingPageHeader: value })
  }, [])

  const updateFormMessage = useCallback((value) => {
    formRef.current.setValues({ landingPageMessage: value })
  }, [])

  const updateFormComment = useCallback((value) => {
    formRef.current.setValues({ autoGeneratedComment: value })
  }, [])

  const updateFormDate = useCallback((value) => {
    if (value === formRef.current.values.autoCloseByDate) {
      formRef.current.setValues({ autoCloseByDate: null })
    } else if (value && (value < new Date())) {
      formRef.current.setValues({ autoCloseByDate: value, locked: true })
    } else {
      formRef.current.setValues({ autoCloseByDate: value })
    }
  }, [])

  const descriptionProps = useDebouncedField(form.values.internalNote, updateFormDescription)
  const headerProps = useDebouncedField(form.values.landingPageHeader, updateFormHeader)
  const messageProps = useDebouncedField(form.values.landingPageMessage, updateFormMessage)
  const commentProps = useDebouncedField(form.values.autoGeneratedComment, updateFormComment)

  return (
    <Stack justify='flex-start'>
      <TextInput
        label='Internal Note'
        description='Optional - never displayed to the candidate'
        placeholder='Previous invite expired - sent 05/07'
        { ...form.getInputProps('internalNote') }
        { ...descriptionProps }
      />
      <TextInput
        label='Landing Page Header'
        description='Custom greeting header shown to the candidate when this link is used'
        placeholder='Optional - default is "Open Opportunity"'
        { ...form.getInputProps('landingPageHeader') }
        { ...headerProps }
      />
      <TextInput
        label='Landing Page Message'
        description='Custom greeting message shown to the candidate below the header'
        placeholder='Optional - default is header only, no message'
        { ...form.getInputProps('landingPageMessage') }
        { ...messageProps }
      />
      <DateTimePicker
        label='Expiration Date'
        description='Automatically lock invite by this date'
        placeholder='Optional'
        {...form.getInputProps('autoCloseByDate')}
        clearable
        onChange={updateFormDate}
      />
      <LinkPrefixEditor form={form} />
      <Select
        label='Landing Page Style'
        searchable
        data={ inviteStyleOptions }
        { ...form.getInputProps('landingPageStyle') }
      />
      <ComingSoonTooltip>
        <Select
          label='Thank You Page Style'
          description='Page displayed to candidate after completing all assessments in phase'
          searchable
          data={ thankYouStyleOptions }
          { ...form.getInputProps('thankYouPageStyle') }
          disabled={true}
        />
      </ComingSoonTooltip>
      {!!isAdmin && (
        <>
          <OverrideDefaultFieldInput
            form={form}
            field='proctored'
            label='Proctor Assessments'
            description='Require ProctorFree running while taking all assessments in phase'
            toggleLabel='proctoring assessments'
            hasData={hasSourceData}
          />
          <OverrideDefaultFieldInput
            form={form}
            field='collectDemographics'
            label='Collect Demographics'
            description='Request demographics from candidate upon completing all assessments in phase'
            toggleLabel='collecting demographics'
            hasData={hasSourceData}
          />
        </>
      )}
      <Checkbox
        label='Email on Submit'
        description='Email candidate upon completing all assessments in phase'
        { ...form.getInputProps('emailOnSubmit') }
        checked={form.values.emailOnSubmit}
      />
      <ComingSoonTooltip>
        <TextInput
          label='Auto Comment'
          description='Added to applicant upon using the link'
          placeholder='Optional'
          { ...form.getInputProps('autoGeneratedComment') }
          { ...commentProps }
          disabled={true}
        />
      </ComingSoonTooltip>
    </Stack>
  )
}
