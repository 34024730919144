/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { Center, Checkbox, Group, Select, SimpleGrid, Table } from '@mantine/core'
import { ConfigModal } from '../cycle/invites/sent/ConfigModal';

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Program ({ form, getSubmissions, cycles, applicants, showPhase, showBattery }) {
  const [lastSubmissions, setLastSubmissions] = useState([])
  const [phaseInviteStatuses, setPhaseInviteStatuses] = useState(null)
  const [apCycleId, setApCycleId] = useState(0)
  const formRef = useRef(form)

  useEffect(() => {
    formRef.current = form
  }, [form])

  useEffect(() => () => {
    formRef.current.setValues(prev => ({ ...prev, ap_cycle: null, retakes: [], passportRetakes: [], inviteConfigTemplate: null }))
  }, [])

  useEffect(() => {
    if (apCycleId > 0) {
      setPhaseInviteStatuses(null)
      getSubmissions(apCycleId).then(response => {
        setLastSubmissions(response.data)
      })
    } else {
      setLastSubmissions([])
    }
  }, [apCycleId])

  useEffect(() => {
    const newRetakes = []

    lastSubmissions.forEach(submission => {
      if (_.find(applicants, a => parseInt(a) === parseInt(submission.id), 0) !== undefined) {
        newRetakes.push({ submission: submission, retake: true })
      }
    })
    formRef.current.setValues(prev => ({ ...prev, retakes: newRetakes }))
  }, [lastSubmissions, applicants])

  const updateInviteConfig = useCallback((config) => {
    console.debug('Updating invite config', { config: config, form: formRef.current })
    if (config) {
      formRef.current.setValues(prev => ({
        ...prev,
        ap_cycle: null,
        retakes: [],
        passportRetakes: config.phase && (prev.inviteConfigTemplate?.phase === config.phase) ? prev.passportRetakes : [],
        inviteConfigTemplate: config
      }))
      setApCycleId(0)
    } else {
      formRef.current.setFieldValue('inviteConfigTemplate', config)
    }
  }, [])

  const cyclesOptions = useMemo(() => {
    return cycles.map(cycle => ({ value: cycle.id.toString(), label: cycle.name }))
  }, [cycles])

  console.debug(
    'Program component updating. Implement new feature using inviteConfigTemplate rather than form ap_cycle.',
    { form, cyclesOptions, apCycleId, lastSubmissions, applicants, phaseInviteStatuses }
  )

  return (
    <SimpleGrid cols={1}>
      <Group>
        <ConfigModal
          updateInviteConfig={updateInviteConfig}
          inviteConfigTemplate={form.values.inviteConfigTemplate}
          offerOption={!form.values.ap_cycle && showPhase}
          applicants={applicants}
          setPhaseInviteStatuses={setPhaseInviteStatuses}
        />
        {!form.values.inviteConfigTemplate && !!showBattery && (
          <Select
            label='Battery'
            placeholder='Select a battery to invite to...'
            data={cyclesOptions}
            {...form.getInputProps('ap_cycle')}
            onChange={value => {
              form.setFieldValue('ap_cycle', value)
              setApCycleId(value)
              if (value) {
                form.setFieldValue('inviteConfigTemplate', null)
              }
            }}
          />
        )}
      </Group>
      {
        !_.isEmpty(form.values.retakes) &&
        <Table verticalSpacing='xs'>
          <caption>The following applicants have already taken the selected battery of assessments</caption>
          <thead>
          <tr>
            <th>Applicant</th>
            <th>Submitted At</th>
            <th>Assessment Status</th>
            <th>Retake</th>
          </tr>
          </thead>
          <tbody>
          {
            form.values.retakes.map((retake, index) => (
              <tr key={`last-submission-${retake.submission.id}`}>
                <td>{retake.submission.email}</td>
                <td>{typeof retake.submission.submitted_at !== 'undefined' ? dayjs(retake.submission.submitted_at).format('MMMM Do YYYY, h:mm a') : 'Never completed'}</td>
                <td>{retake.submission.status}</td>
                <td><Center><Checkbox{...form.getInputProps(`retakes.${index}.retake`, { type: 'checkbox' })}/></Center></td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      }
      <PhaseInviteStatuses
        form={form}
        phaseInviteStatuses={phaseInviteStatuses}
        applicants={applicants}
      />
    </SimpleGrid>
  )
}

function PhaseInviteStatuses ({ form, phaseInviteStatuses, applicants }) {
  const formRef = useRef(form)
  const applicantsRef = useRef(applicants)

  useEffect(() => {
    formRef.current = form
  }, [form])

  useEffect(() => {
    applicantsRef.current = applicants
    const previousRetakes = formRef.current.values.passportRetakes
    console.debug('Filtering form passport retakes from applicants change', { applicants, previousRetakes })
    const retakeMap = new Map()
    for (const retake of previousRetakes) {
      retakeMap.set(retake.id, retake)
    }
    const selected = new Set(applicants)
    formRef.current.setFieldValue('passportRetakes', [...retakeMap.values()].filter(retake => selected.has(retake.id)))
  }, [applicants])

  useEffect(() => {
    const previousRetakes = formRef.current.values.passportRetakes
    console.debug(
      'Updating form passport retakes from statuses change',
      { phaseInviteStatuses, previousRetakes }
    )
    const retakeMap = new Map()
    for (const retake of previousRetakes) {
      retakeMap.set(retake.id, retake)
    }
    if (phaseInviteStatuses?.retakes?.length) {
      for (const retake of phaseInviteStatuses.retakes) {
        if (!retakeMap.has(retake.id)) {
          retakeMap.set(retake.id, { ...retake, retake: true, retakeRequired: true, unlock: retake.locked })
        }
      }
    }
    if (phaseInviteStatuses?.resumePhase?.length) {
      for (const resume of phaseInviteStatuses.resumePhase) {
        if (!retakeMap.has(resume.id)) {
          retakeMap.set(resume.id, { ...resume, retake: false, retakeRequired: null, unlock: resume.locked })
        }
      }
    }
    if (phaseInviteStatuses?.continueFromLastPhase?.length) {
      for (const continued of phaseInviteStatuses.continueFromLastPhase) {
        if (!retakeMap.has(continued.id) && continued.locked) {
          retakeMap.set(continued.id, { ...continued, retake: false, retakeRequired: false, unlock: continued.locked })
        }
      }
    }
    const selected = new Set(applicantsRef.current)
    formRef.current.setFieldValue('passportRetakes', [...retakeMap.values()].filter(retake => selected.has(retake.id)))
  }, [phaseInviteStatuses])

  console.debug(
    'PhaseInviteStatuses component updating',
    { phaseInviteStatuses: phaseInviteStatuses, applicants: applicants, retakes: form.values.passportRetakes }
  )

  return (
    <>
      {
        !_.isEmpty(form.values.passportRetakes) &&
        <Table verticalSpacing='xs'>
          <caption>These applicants have already started at least one of the assessments in this phase, or have previously been manually locked.</caption>
          <thead>
          <tr>
            <th>Applicant</th>
            <th>Last Activity for Phase</th>
            <th>Assessment Status</th>
            <th>Most Recent Phase</th>
            <th>Active Assessment</th>
            <th>Locked</th>
            <th>Unlock</th>
            <th>Retake</th>
          </tr>
          </thead>
          <tbody>
          {
            form.values.passportRetakes.map((retake, index) => (
                <tr key={`last-submission-${retake.id}`}>
                  <td>{retake.identifier}</td>
                  <td>{retake.submittedAt ? dayjs(retake.submittedAt).format('MMMM Do YYYY, h:mm a') : 'Never completed'}</td>
                  <td>{retake.startFirstStageInPhase ? 'Completed' : 'Resume'}</td>
                  <td>{retake.lastPhaseId}</td>
                  <td>{(retake.locked && !retake.unlock) ? 'None (not unlocked)' : (retake.retake ? retake.firstAssessment.name : (retake.continueOnAssessment ? retake.continueOnAssessment.name : 'None (not retaking)'))}</td>
                  <td>{retake.locked ? 'Yes' : 'No'}</td>
                  <td>
                    {retake.locked
                      ? (
                        <Center><Checkbox{...form.getInputProps(`passportRetakes.${index}.unlock`, { type: 'checkbox' })}/></Center>
                        )
                      : <Center>-</Center>}
                  </td>
                  <td>
                    {(retake.retakeRequired !== false)
                      ? (
                        <Center><Checkbox{...form.getInputProps(`passportRetakes.${index}.retake`, { type: 'checkbox' })}/></Center>
                        )
                      : <Center>-</Center>}
                  </td>
                </tr>
            ))
          }
          </tbody>
        </Table>
      }
    </>
  )
}
