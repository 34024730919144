import {
  useGetAssignedCyclePhasesQuery, useGetAssignedCyclesGroupPhasesQuery,
  useGetCycleApplicantsQuery,
  useGetCycleStageGenerationStatusQuery
} from '../../../../redux/query/hire/cyclesApi.slice'
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice';
import { isGranted } from '../../../../util/account/account.util';
import { useValidatePhaseInvitesQuery } from './sent/AssessmentInvitesApi';
import { apiSlice } from '../../../api';

const limit = -1

export function useAllActiveCycleApplicants (cycleId, params = null, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleApplicantsQuery({ ...(params ?? {}), cycleId, limit }, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleAssignedPhases (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetAssignedCyclePhasesQuery(cycleId, { skip })
  return [data ?? null, querying, loading]
}

export function useCyclesGroupAssignedPhases (cycleIds, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetAssignedCyclesGroupPhasesQuery(cycleIds, { skip })
  return [data ?? null, querying, loading]
}

export function useValidatePhaseInvites (phaseId, applicantIds, cycleIds, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useValidatePhaseInvitesQuery({ phaseId, applicantIds, cycleIds }, { skip })
  return [data ?? null, querying, loading]
}

export function useLazyValidatePhaseInvites () {
  const [trigger, { data, isFetching: querying, isLoading: loading }] = apiSlice.endpoints.validatePhaseInvites.useLazyQuery()
  return [trigger, data ?? null, querying, loading]
}

export function useCycleStageGenerationStatus (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleStageGenerationStatusQuery(cycleId, { skip })
  return [data ?? null, querying, loading]
}

/**
 * @returns {[boolean, boolean]}
 */
export function useIsAdmin () {
  const { data: account, isLoading: accountLoading } = useGetLoggedInAccountQuery()
  return [account ? isGranted(account, 'ROLE_ADMIN') : false, accountLoading]
}

/**
 * @returns {[boolean, boolean]}
 */
export function useIsBeta () {
  const { data: account, isLoading: accountLoading } = useGetLoggedInAccountQuery()
  return [account ? isGranted(account, 'ROLE_BETA_TESTER') : false, accountLoading]
}
