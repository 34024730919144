import { createContext } from 'react';

/**
 * @typedef {int[]} CyclesContext
 */

/**
 * @type {CyclesContext}
 */
export const defaultCyclesContext = []

/**
 * @type {React.Context<CyclesContext>}
 */
export const CyclesContext = createContext(defaultCyclesContext)
