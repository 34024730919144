import { ApiAbstractIds, ApiMethods, apiSlice, ApiTags } from '../../../../api';
import { cycleListTag, paramsToQuery } from '../../../../util/queries';
import { injectNullFlags } from '../queryUtil';

export const AssessmentInvitesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssessmentInvites: builder.query({
      query: (params) => `invites/sent/list${paramsToQuery(injectNullFlags(params))}`,
      providesTags: (result) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.AssessmentInvites, id: id })),
              { type: ApiTags.AssessmentInvites, id: ApiAbstractIds.PartialList }
            ]
          : [{ type: ApiTags.AssessmentInvites, id: ApiAbstractIds.PartialList }]
    }),
    getAssessmentInvitesForCycle: builder.query({
      query: ({ cycleId, openInviteId, cyclePassId, ...params }) => cyclePassId ? `invites/sent/passes/${cyclePassId}/list${paramsToQuery(injectNullFlags(params))}` : (openInviteId ? `invites/sent/open/${openInviteId}/list${paramsToQuery(injectNullFlags(params))}` : `invites/sent/cycles/${cycleId}/list${paramsToQuery(injectNullFlags(params))}`),
      providesTags: (result, _, { cycleId: cycleIdParam }) =>
        result?.items
          ? [
              ...result.items.map(({ id }) => ({ type: ApiTags.AssessmentInvites, id: id })),
              { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) }
            ]
          : [{ type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) }]
    }),
    getAssessmentInviteDetail: builder.query({
      query: ({ assessmentInviteId }) => `invites/sent/${assessmentInviteId}`,
      providesTags: ({ id }, _, { assessmentInviteId: assessmentInviteIdParam, cycleId: cycleIdParam }) =>
        [{ type: ApiTags.AssessmentInvites, id: id ?? assessmentInviteIdParam }, { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) }]
    }),
    validatePhaseInvites: builder.query({
      query: ({ phaseId, ...params }) => `invites/sent/phases/${phaseId}/validateApplicants${paramsToQuery(params)}`,
      providesTags: (result, _, { applicantIds: applicantIdsParam, cycleIds: cycleIdsParam }) =>
        [
          ...(cycleIdsParam?.map(cycleIdParam => ({ type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) })) ?? null),
          ...(cycleIdsParam?.map(cycleIdParam => ({ type: ApiTags.Applicants, id: cycleListTag(cycleIdParam) })) ?? null),
          ...(applicantIdsParam?.map(applicantIdParam => ({ type: ApiTags.Applicants, id: applicantIdParam })) ?? null)
        ]
    }),
    createAssessmentInvite: builder.mutation({
      query: ({ cycleId, ...body }) => ({
        url: `invites/sent/cycles/${cycleId}/create`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { cycleId: cycleIdParam }) => [
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: ApiAbstractIds.PartialList }
      ]
    }),
    editAssessmentInvite: builder.mutation({
      query: ({ id, cycleId, ...body }) => ({
        url: `invites/sent/${id}/edit`,
        method: ApiMethods.Post,
        body: body
      }),
      invalidatesTags: (result, error, { id: idParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: idParam }
      ]
    }),
    assessmentInviteLock: builder.mutation({
      query: ({ id, cycleId, unlock }) => ({
        url: unlock ? `invites/sent/${id}/unlock` : `invites/sent/${id}/lock`,
        method: ApiMethods.Post,
        body: {}
      }),
      invalidatesTags: (result, error, { id: idParam, cycleId: cycleIdParam }) => [
        { type: ApiTags.AssessmentInvites, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.CyclePasses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.StageProgresses, id: cycleListTag(cycleIdParam) },
        { type: ApiTags.AssessmentInvites, id: idParam }
      ]
    })
  })
})

export const {
  useGetAssessmentInvitesQuery,
  useGetAssessmentInvitesForCycleQuery,
  useGetAssessmentInviteDetailQuery,
  useValidatePhaseInvitesQuery,
  useCreateAssessmentInviteMutation,
  useEditAssessmentInviteMutation,
  useAssessmentInviteLockMutation
} = AssessmentInvitesApi
