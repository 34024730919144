/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Button, Group, SimpleGrid } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'
import { isNotEmpty, useForm } from '@mantine/form'
import Messages from './Messages'
import { randomId } from '@mantine/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Program from './Program'
import { contact } from '../../../js/api/email_repository'
import { showNotification } from '@mantine/notifications'
import FormError from '../../forms/FormError'
import NoticeAlert from '../../core/Alert/NoticeAlert'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Form ({
  applicants,
  getTemplates,
  getTextTemplates,
  getBatteries,
  getSubmissions,
  onSuccessRedirect,
  previewApplicant
}) {
  const [cycles, setCycles] = useState([])
  const [templates, setTemplates] = useState([])
  const [textTemplates, setTextTemplates] = useState([])
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      applicants: applicants,
      messages: [
        {
          key: randomId(),
          type: 'email',
          send_at: null,
          template: null,
          subject: '',
          body: ''
        }
      ],
      retakes: [],
      ap_cycle: null,
      passportRetakes: [],
      inviteConfigTemplate: null
    },
    transformValues: values => {
      const transformedValues = {
        applicants: applicants,
        emails: [],
        texts: [],
        retakes: [],
        ap_cycle: values.ap_cycle,
        passportRetakes: [],
        inviteConfigTemplate: values.inviteConfigTemplate
      }

      values.messages.forEach(message => {
        if (message.type === 'email') {
          transformedValues.emails.push({
            send_at: message.send_at !== null ? dayjs(message.send_at).format() : null,
            template: message.template,
            subject: message.subject,
            body: message.body
          })
        } else {
          transformedValues.texts.push({
            send_at: dayjs(message.send_at).format(),
            template: message.template,
            body: message.body
          })
        }
      })

      values.retakes.forEach(retake => {
        transformedValues.retakes.push({ applicant: retake.submission.id, retake: retake.retake ? '1' : '0', retakeRequired: '1', unlock: '0' })
      })

      values.passportRetakes.forEach(retake => {
        transformedValues.passportRetakes.push({ applicant: retake.id, retake: retake.retake ? '1' : '0', retakeRequired: (retake.retakeRequired || retake.retake) ? '1' : '0', unlock: retake.unlock ? '1' : '0' })
      })

      return transformedValues
    },
    validate: {
      applicants: isNotEmpty('At least one applicant needs to be selected'),
      messages: {
        subject: (value, values, path) => {
          const index = _.split(path, '.', 2)[1]

          if (values.messages[index].type === 'email' && value === '') {
            return 'Subject cannot be empty'
          }

          return null
        },
        body: value => value !== '' && value !== '<p></p>' ? null : 'Body cannot be empty',
        template: (value, values, path) => {
          const index = _.split(path, '.', 2)[1]

          if (values.messages[index].type === 'text' && value === null) {
            return 'Please choose a template'
          }

          return null
        }
      },
      messagesNotEmpty: (value, values) => _.size(values.messages) < 1 ? 'At least one message needs to be added' : null,
      ap_cycle: (value, values) => {
        let hasInvitation = false
        let hasInvalidInvitation = false

        values.messages.forEach(message => {
          if (messageIsAssessmentInvite(message, templates, textTemplates, true, false)) {
            hasInvitation = true
          }
          if (messageIsAssessmentInvite(message, templates, textTemplates, false, true)) {
            hasInvalidInvitation = true
          }
        })

        if (hasInvitation && hasInvalidInvitation) {
          return 'Cannot use both a battery and phase invite template'
        }

        if (!hasInvitation && value) {
          return 'Must select at least one message template with the corresponding tag for a battery invite'
        }
        return (hasInvitation && ((value === null) || !!values.inviteConfigTemplate)) ? 'Must select a battery for message templates with the battery invite tag' : null
      },
      inviteConfigTemplate: (value, values) => {
        let hasInvitation = false

        values.messages.forEach(message => {
          if (messageIsAssessmentInvite(message, templates, textTemplates, false, true)) {
            hasInvitation = true
          }
        })

        if (!hasInvitation && value) {
          return 'Must select at least one message template with the corresponding tag for a phase invite'
        }
        return (hasInvitation && (!value?.phase || !!values.ap_cycle)) ? 'Must select a phase for message templates with the phase invite tag' : null
      }
    }
  })

  useEffect(() => {
    getTemplates().then(response => setTemplates(response.data))
    getTextTemplates().then(response => setTextTemplates(response.data))
    getBatteries().then(response => setCycles(response.data))
  }, [])

  useEffect(() => {
    form.setFieldValue('applicants', applicants)
  }, [applicants])

  const [showBattery, showPhase] = useMemo(() => {
    let newShowPhase = false
    let newShowBattery = false
    for (const message of form.values.messages) {
      if (messageIsAssessmentInvite(message, templates, textTemplates)) {
        if (!newShowPhase && messageIsAssessmentInvite(message, templates, textTemplates, false, true)) {
          newShowPhase = true
        }
        if (!newShowBattery && messageIsAssessmentInvite(message, templates, textTemplates, true, false)) {
          newShowBattery = true
        }
        if (newShowPhase && newShowBattery) {
          break
        }
      }
    }
    return [newShowBattery, newShowPhase]
  }, [form.values.messages])

  const show = showPhase || showBattery

  const numEmails = _.size(_.filter(form.values.messages, { type: 'email' }))
  const numTexts = _.size(_.filter(form.values.messages, { type: 'text' }))

  const onSubmit = async values => {
    console.debug('Called form onSubmit', { values })
    setLoading(true)

    const success = await contact(values)

    if (success) {
      window.location.replace(onSuccessRedirect)
    } else {
      showNotification({
        title: 'Something went wrong',
        message: 'There was an error contacting the applicants',
        color: 'red'
      })
      setLoading(false)
    }
  }

  console.debug('Form updating', { form, loading, applicants })

  const excludedCount = form.values.passportRetakes.filter(retake => (!retake.retake && retake.retakeRequired) || (!retake.unlock && retake.locked)).length
  return (
    <form onSubmit={form.onSubmit(values => onSubmit(values))}>
      <SimpleGrid cols={1}>
        <Messages form={form} templates={templates} textTemplates={textTemplates} previewApplicant={previewApplicant}/>
        {show && <Program form={form} getSubmissions={getSubmissions} applicants={applicants} cycles={cycles} showPhase={showPhase} showBattery={showBattery} />}
        <NoticeAlert>
          <SimpleGrid cols={1}>
            <div>
              {_.size(form.values.messages) > 0 && _.size(applicants) > 0 && (
                <>
                  You are about to send {numEmails > 0 && ' ' + numEmails + ' email' + (numEmails === 1 ? '' : 's')} {numTexts > 0 && (numEmails > 0 ? ' and ' : ' ') + numTexts + ' text message' + (numTexts === 1 ? '' : 's')} to {_.size(applicants) - excludedCount} applicant{(_.size(applicants) - excludedCount) === 1 ? '' : 's'}.
                  {excludedCount ? ` ${excludedCount} of your selected applicants will not be contacted due to the retake choices above, check the active assessment column to confirm which selected applicants will not receive any messages.` : ''}
                </>
              )}
            </div>
            <div>Be sure to include your email address to your email if you need a reply from an applicant(s). A work email address is recommended.</div>
          </SimpleGrid>
        </NoticeAlert>
        <FormError form={form} name='applicants'/>
        <FormError form={form} name='messagesNotEmpty'/>
        <FormError form={form} name='inviteConfigTemplate' />
        <Group>
          <Button color='blue' leftSection={<IconMail size={18} />} type='submit' disabled={loading}>{loading ? 'Loading...' : 'Send'}</Button>
          <Button color='gray' onClick={() => window.history.back()}>Cancel</Button>
        </Group>
      </SimpleGrid>
    </form>
  )
}

function messageIsAssessmentInvite (message, emailTemplates, textTemplates, allowBattery = true, allowPhase = true) {
  return messageTemplateIsAssessmentInvite(
    message,
    message.type === 'email' ? emailTemplates : textTemplates,
    allowBattery,
    allowPhase
  )
}

function messageTemplateIsAssessmentInvite (message, emailOrTextTemplates, allowBattery, allowPhase) {
  for (const template of emailOrTextTemplates) {
    if (message.template === template.id.toString()) {
      return (tagIsAssessmentInvite(template.category?.tag, allowBattery, allowPhase))
    }
  }
  return false
}

function tagIsAssessmentInvite (tag, allowBattery, allowPhase) {
  return (allowBattery && assessmentTemplateCategoryTags.includes(tag)) || (allowPhase && tagIsPhaseInvite(tag))
}

function tagIsPhaseInvite (tag) {
  return tag && tag.toLowerCase().trim().startsWith(sharedPhaseInvitePrefix)
}

const assessmentTemplateCategoryTags = [
  'proctorfree-invitation',
  'proctorfree-reminder'
]

// const createPhaseInvitePrefix = 'passport-invite-phase-'
// const phaseInviteReminderPrefix = 'passport-invite-reminder-phase-'
const sharedPhaseInvitePrefix = 'passport-invite'
