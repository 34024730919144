import { apiSlice } from '../../api';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../core/ReactTable/paramsSlice';
import {
  useGetCycleQuery,
  useGetCycleApplicantsDetailsQuery,
  useGetCycleDegreesQuery,
  useGetCycleRegionsQuery,
  useGetCycleStatusesQuery,
  useGetCycleTableModuleInfosQuery
} from '../../../redux/query/hire/cyclesApi.slice';

export function useCycle (cycleId) {
  const { data, isFetching: querying } = useGetCycleQuery(cycleId)
  return [data ?? null, querying]
}

export function useLazyCycle (cycleId) {
  const { data, isFetching: querying } = apiSlice.endpoints.getCycle.useQueryState(cycleId)
  return [data ?? null, querying]
}

export function useCycleApplicantsDetailsList (namespace, cycleId, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data, isFetching: querying, isLoading: loading, requestId, fulfilledTimeStamp } = useGetCycleApplicantsDetailsQuery({ ...(queryParams ?? {}), cycleId, namespace }, { skip })
  return [data ?? null, querying, loading, fulfilledTimeStamp ? requestId : null]
}

export function useLazyCycleApplicantsDetailsList (namespace, cycleId) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data, isFetching: querying, isLoading: loading } = apiSlice.endpoints.getCycleApplicantsDetails.useQueryState({ ...(queryParams ?? {}), cycleId, namespace })
  return [data ?? null, querying, loading]
}

export function useCycleTableModuleInfos (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading, isError: error } = useGetCycleTableModuleInfosQuery({ cycleId }, { skip })
  return [data ?? null, querying, loading, error]
}

export function useCycleStatuses (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleStatusesQuery({ cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleRegions (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleRegionsQuery({ cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleDegrees (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleDegreesQuery({ cycleId }, { skip })
  return [data ?? null, querying, loading]
}
